import React from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  return (
    <div className="w-[100%] mb-2 p-2 flex flex-col md:flex-row justify-between items-center">
      <div className="w-[70px]">
        <Link to={"/"}>
          <img
            className="rounded-lg"
            src={require("../assets/images/logo_without_bg.png")}
            alt="company logo"
          />
        </Link>
      </div>
      <div className="text-[#3E6148]">
        <NavLink
          className="px-5 cursor-pointer border-r-2 border-l-2 border-[#3E6148]"
          to={"/"}
          onClick={() => {
            window.scrollTo({
              top: 650,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          About Us
        </NavLink>
        <NavLink
          className="px-5 cursor-pointer border-r-2 border-[#3E6148]"
          to={"/"}
          onClick={() => {
            window.scrollTo({
              top: 1300,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Products
        </NavLink>
        <NavLink
          className="px-5 cursor-pointer border-r-2 border-[#3E6148]"
          to={"/"}
        >
          Contact Us
        </NavLink>
      </div>
    </div>
  );
};

export default Header;
