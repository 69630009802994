import React from "react";

const socialMedia = {
  fb: "https://www.facebook.com/profile.php?id=61565475251057&sk=about",
  insta: "https://www.instagram.com/shimla_spice/",
  linkedin: "https://www.linkedin.com",
};
const Footer = () => {
  const handleSocialMedia = (socialMediaURL) => {
    window.open(socialMedia[socialMediaURL], "_blank", "noopener,noreferrer");
  };
  return (
    <div className="flex items-center flex-col px-6 py-8 bg-[#d8dac3]">
      <div className="flex flex-col lg:flex-row mb-5">
        <li
          className="list-none flex mr-6 items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            handleSocialMedia("fb");
          }}
        >
          <img
            className="w-7"
            src={require("../assets/images/fb.png")}
            alt="facebook icon"
          />
          Facebook
        </li>
        <li
          className="list-none flex mr-6 items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            handleSocialMedia("insta");
          }}
        >
          <img
            className="w-7"
            src={require("../assets/images/insta.png")}
            alt="instagram icon"
          />
          Instagram
        </li>
        <li
          className="list-none flex mr-6 items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            handleSocialMedia("linkedin");
          }}
        >
          <img
            className="w-7"
            src={require("../assets/images/linkedin.png")}
            alt="linkedin icon"
          />
          LinkedIn
        </li>
      </div>
      <div className="flex">Copyright | 2024</div>
    </div>
  );
};

export default Footer;
