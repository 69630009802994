import React from "react";
import AboutUs from "./AboutUs";

const HomeBanner = () => {
  return (
    <div className="relative">
      <img
        className="w-full h-[600px] rounded-xl"
        src={require("../assets/images/banner2.png")}
        alt=""
      />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center z-10">
        <h1 className="text-5xl text-white font-bold mb-4">
          Welcome to Shimla Spice
        </h1>
        <h1 className="text-4xl text-white mb-3">
          Bringing the Heart of Himachal to Your Plate.
        </h1>
        <button
          className="w-36 font-bold h-12 mt-2 text-white bg-[#a6b68a8a] rounded-lg"
          onClick={() => {
            window.scrollTo({
              top: 650,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Learn More
        </button>
      </div>
    </div>
  );
};

export default HomeBanner;
