import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "./App.css";
import Body from "./components/Body";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppContainer />,
      children: [{ path: "/", element: <Body /> }],
    },
    { path: "*", element: <>Page Not Found</> },
  ]);

  return (
    <RouterProvider router={appRouter}>
      <AppContainer />
    </RouterProvider>
  );
}

const AppContainer = () => {
  return (
    <div className="w-[100%] min-h-[400px] bg-[#f8f4ed] px-6 font-sans">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default App;
