import React from "react";

const AboutUs = () => {
  return (
    <div className="mt-4">
      <h1 className="text-4xl font-bold text-center my-10" id="about">
        About Us
      </h1>
      <div className="flex  flex-col md:flex-row mb-48">
        <div className="lg:w-1/3 sm:max-md:w-full  text-center p-8">
          <h2 className="font-bold text-2xl mb-6">Inception</h2>
          <p className="text-xl px-7">
            Shimla Spice is born out of a passion for preserving the rich
            culinary traditions of Himachal Pradesh. We offer a range of
            homemade pickles and other preparations, made with love and the
            finest ingredients. Our products are a celebration of the unique
            flavors and recipes that have been passed down through generations.
          </p>
        </div>
        <div className="lg:w-1/3 sm:max-md:w-full  text-center p-8">
          <h2 className="font-bold text-2xl mb-6">Our Products</h2>
          <p className="text-xl px-7">
            Explore our selection of homemade pickles and spice blends, each
            crafted to bring you a taste of Himachal Pradesh. Whether you love
            the tangy zest of lemon pickle or the fiery heat of chili pickle,
            Shimla Spice has something to suit every palate.
          </p>
        </div>
        <div className="lg:w-1/3 sm:max-md:w-full  text-center p-8">
          <h2 className="font-bold text-2xl mb-6">Why us?</h2>
          <p className="text-xl px-7">
            Authentic Recipes: We use traditional recipes that bring the true
            flavors of Himachal to your plate. Quality Ingredients: All our
            products are made with locally sourced, fresh ingredients, ensuring
            every jar is packed with flavor. Homemade Goodness: Our pickles are
            made in small batches to maintain the highest quality and taste.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
