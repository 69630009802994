import React, { useRef } from "react";
import HomeBanner from "./HomeBanner";
import AboutUs from "./AboutUs";
import Products from "./Products";

const Body = () => {
  const ScrollDemo = () => {
    const myRef = useRef(null);

    const executeScroll = () => myRef.current.scrollIntoView();
    // run this function from an event handler or an effect to execute scroll

    return (
      <>
        <div ref={myRef}>Element to scroll to</div>
        <button onClick={executeScroll}> Click to scroll </button>
      </>
    );
  };
  return (
    <div className="w-[100%] min-h-[calc(100vh-8rem) text-[#3E6148]">
      <HomeBanner />
      <AboutUs />
      <Products />
    </div>
  );
};

export default Body;
